import React, { useEffect } from "react";
import { useStaticQuery } from "gatsby";

import { useCurrency } from "context/currencyContext";
import { useCategories } from "context/categoriesContext";
import { useBrands } from "context/brandsContext";

import { isNotEmpty } from "utils/helper";

import Main from "components/Layout/main";
import QuoteSlider from "components/UI/quoteSlider";
import ProductSlider from "components/UI/productSlider";
import ParentCategoryHero from "components/UI/Listpage/parentCategoryHero";
import HomepageBottomBanner from "components/UI/Homepage/homepageBottomBanner";
import ParentCategoryDropdown from "components/UI/Listpage/parentCategoryDropDown";
import ParentCategoryDropdownFAQ from "components/UI/Listpage/parentCategoryDropDownFAQ";
import RoundImageBanner from "components/UI/roundImageBanner";
import HtmlHead from "components/UI/htmlHead";
import BlogSlider from "components/UI/blogSlider";
import ProductPageDotsSlider from "components/UI/ProductPage/productPageDotsSlider";

import "styles/listpage.scss";

import { listpageQuery } from "components/UI/Listpage/listpageContent";
import { GTMSelectItemEvent, GTMViewItemListEvent } from "components/GTM/gtmCategory";

import { HAVE_BETTER_SEX, LAST_LONGER, HAIR_LOST, TOP_PRODUCTS } from "context/categoriesContext";


const Category = (props) => {
    const { pageContext } = props;
    const { categoryId, allContentfulPromoBanner, categoryCode } = pageContext;

    const queryData = useStaticQuery(listpageQuery);

    let title = '';
    if (categoryCode === HAVE_BETTER_SEX)
        title = "Have better sex";
    else if (categoryCode === LAST_LONGER)
        title = "last longer";

    let categoryTitle = '';
    if (categoryCode === HAVE_BETTER_SEX)
        categoryTitle = "Erectiestoornis";
    else if (categoryCode === LAST_LONGER)
        categoryTitle = "Vroegtijdige ejaculatie";

    const products = queryData.allBigCommerceProducts.nodes;
    const categoryProducts = products.filter((product) =>
        product?.categories.includes(categoryId)
    );
    const currentPromoBanner = pageContext.title === "Betere seks" ?
        allContentfulPromoBanner?.nodes.find(el => el.location === "Seksuele gezondheid") :
        allContentfulPromoBanner?.nodes.find(el => el.location === pageContext.title)


    const sliders = queryData.allContentfulSlider.edges;
    const [quoteSliderData] = sliders.filter(
        (slider) =>
            slider.node.type === "quote-slider" &&
            slider.node.location === `category-page ${title}`
    );
    const RoundImageBannerData = sliders.filter(
        (slider) =>
            slider.node.type === "product" &&
            slider.node.location === "homepage"
    );

    const [dotsSliderData] = sliders.filter(
        (slider) =>
            slider.node.type === "dots-slider" &&
            slider.node.location === `category-page ${title}`
    );

    const [blogSliderData] = sliders.filter(
        (slider) =>
            slider.node.type === "blog-slider" &&
            slider.node.location === `category-page ${title}`
    );

    const banners = queryData.allContentfulBannerUpdated.edges;
    const [productsSliderData] = banners.filter(
        (banner) =>
            banner.node.type === "products" &&
            banner.node.location === `category-page ${title}`
    );
    const [FAQBannerData] = banners.filter(
        (banner) =>
            banner.node.type === "faq-banner" &&
            banner.node.location === `category-page ${title}`
    );

    const getStyle = () => {
        let backgroundColor;
        switch (categoryCode) {
            case HAIR_LOST:
                backgroundColor = '#DDE4E5';
                break;
            case HAVE_BETTER_SEX:
                backgroundColor = '#F4EEDA';
                break;
            case LAST_LONGER:
                backgroundColor = '#F4EEDA';
                break;
            case TOP_PRODUCTS:
                backgroundColor = '#F7E7D2';
                break;
            default:
                backgroundColor = '#DDE4E5';
                break;
        }

        return backgroundColor ?
            `:root { --background-main: ${backgroundColor};}` :
            '';
    }

    const CategoryContent = () => {
        const { currency } = useCurrency();
        const { getCategoryName } = useCategories();
        const { getBrandName } = useBrands();

        const GTMData = {
            item_list_id: categoryId,
            item_list_name: title,
            items: [...categoryProducts.map((product, index) => {
                return {
                    item_id: product.bigcommerce_id,
                    item_name: product.name,
                    currency: currency.currency_code,
                    discount: 0,
                    index,
                    item_brand: getBrandName(product.brand_id),
                    item_category: getCategoryName(categoryId),
                    item_category2: getCategoryName(product.categories.find(id => id !== categoryId)),
                    item_variant: product.variants[0].id,
                    price: product.price,
                }
            })],
        }
        const productSelectClick = (productId) => {
            GTMSelectItemEvent({
                ...GTMData,
                items: [GTMData.items.find(item => item.item_id === productId)]
            });
        };

        useEffect(() => {
            isNotEmpty(categoryProducts) && isNotEmpty(currency) && GTMViewItemListEvent(GTMData);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [categoryProducts, currency]);

        return (
            <>
                <ParentCategoryHero categoryName={title}/>
                <div className="homepage__content category-page__content">
                    <ParentCategoryDropdown categoryName={title}/>
                    {isNotEmpty(categoryProducts) ? (
                        <ProductSlider
                            products={categoryProducts}
                            isHomepage
                            isHideButtons
                            title={productsSliderData?.node?.title}
                            subtitle={productsSliderData?.node?.plainText}
                            productSelectClick={productSelectClick}
                        />
                    ) : (
                        <div className="empty-slider-placeholder">
                            There is no product yet
                        </div>
                    )}
                    {dotsSliderData && (
                        <div className="product-page__dots-slider">
                            <p className="typography__h2 product-page__dots-slider-title">{dotsSliderData?.node.sliderTitle}</p>
                            <ProductPageDotsSlider slides={dotsSliderData?.node}/>
                        </div>
                    )}
                    <RoundImageBanner bannerData={RoundImageBannerData[0].node}/>
                    {quoteSliderData && (
                        <QuoteSlider sliderData={quoteSliderData.node}/>
                    )}
                    <HomepageBottomBanner
                        questionnaireLink={categoryCode === HAVE_BETTER_SEX ? '/questionnaire-erectile-dysfunction' : '/questionnaire-premature-ejaculation'}/>
                    {FAQBannerData && (
                        <ParentCategoryDropdownFAQ banner={FAQBannerData.node}/>
                    )}
                    {blogSliderData && (
                        <BlogSlider sliderData={blogSliderData.node}/>
                    )}
                </div>
            </>
        )
    }

    const gtmData = {
        page: {
            title,
            type: "Category",
        },
    };

    return (
        <Main promoBannerData={currentPromoBanner} className="listpage" gtmData={gtmData}>
            <HtmlHead title={categoryTitle} style={getStyle()}/>
            <CategoryContent/>
        </Main>
    );
};

export default Category;
